import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // 首頁導向
  {
    path: "/",
    redirect: "/login",
  },
  // 登入頁
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
    beforeEnter: () => {
      if (localStorage.getItem("oceanPowerToken")) {
        return { path: "/oceanPower/user/list" };
      }
    },
  },
  // 404頁面
  {
    path: "/ErrorPage",
    name: "ErrorPage",
    component: () => import("../layout/ErrorPage.vue"),
  },
  { path: "/:pathMatch(.*)", redirect: "/ErrorPage" },
  // 後台群組
  {
    path: "/oceanPower",
    name: "oceanPower",
    redirect: "/oceanPower/user/list",
    component: () => import("../layout/SideBar.vue"),
    children: [
      // 職員
      {
        path: "user",
        meta: {
          group: "基本資料",
          basicRouter: {
            name: "海威職員",
            router: "/oceanPower/user/list",
          },
        },
        children: [
          // 列表
          {
            path: "list",
            component: () => import("../views/BasicData/User/UserList.vue"),
          },
          // 新增
          {
            path: "create",
            component: () => import("../views/BasicData/User/UserCreate.vue"),
            meta: {
              title: "新增職員",
            },
          },
          // 編輯
          {
            path: "edit/:id",
            component: () => import("../views/BasicData/User/UserEdit.vue"),
            meta: {
              title: "編輯職員",
            },
          },
          // 檢視
          {
            path: "view/:id",
            component: () => import("../views/BasicData/User/UserView.vue"),
            meta: {
              title: "檢視職員",
            },
          },
        ],
      },
      // 客戶
      {
        path: "client",
        meta: {
          group: "基本資料",
          basicRouter: {
            name: "海威客戶",
            router: "/oceanPower/client/list",
          },
        },
        children: [
          // 列表
          {
            path: "list",
            component: () => import("../views/BasicData/Client/ClientList.vue"),
          },
          // 新增
          {
            path: "create",
            component: () =>
              import("../views/BasicData/Client/ClientCreate.vue"),
            meta: {
              title: "新增客戶",
            },
          },
          // 編輯
          {
            path: "edit/:id",
            component: () => import("../views/BasicData/Client/ClientEdit.vue"),
            meta: {
              title: "編輯客戶",
            },
          },
        ],
      },
      // 海快預報
      {
        path: "advanceUpload",
        meta: {
          group: "預報資料",
          basicRouter: {
            name: "海快預報",
            router: "/oceanPower/advanceUpload",
          },
        },
        component: () => import("../views/AdvanceUploadData/AdvanceUpload.vue"),
      },
      // 海快資料
      {
        path: "seaExpressData",
        meta: {
          group: "配送資料",
          basicRouter: {
            name: "海快資料",
            router: "/oceanPower/seaExpressData",
          },
        },
        component: () => import("../views/DeliveryData/SeaExpressData.vue"),
      },
      // 無EDI資料
      {
        path: "noEdiData",
        meta: {
          group: "配送資料",
          basicRouter: {
            name: "無EDI資料",
            router: "/oceanPower/noEdiData",
          },
        },
        component: () => import("../views/DeliveryData/NoEdiData.vue"),
      },
      // 分提單查詢
      {
        path: "houseBillSearch",
        meta: {
          group: "配送資料",
          basicRouter: {
            name: "分提單查詢",
            router: "/oceanPower/houseBillSearch",
          },
        },
        component: () => import("../views/DeliveryData/HouseBillSearch.vue"),
      },
      // 指派主提單
      {
        path: "assignMainCargo",
        meta: {
          group: "配送資料",
          basicRouter: {
            name: "指派主提單",
            router: "/oceanPower/assignMainCargo",
          },
        },
        component: () => import("../views/DeliveryData/AssignMainCargo.vue"),
      },
      // 清關掃描
      {
        path: "scanningPage",
        meta: {
          group: "掃描",
          basicRouter: {
            name: "清關掃描",
            router: "/oceanPower/scanningPage",
          },
        },
        component: () => import("../views/ScanningGroup/ScanningPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const token = localStorage.getItem("oceanPowerToken");
  if (to.fullPath === "/login") return;
  if (!token) {
    return "/login";
  }
  return true;
});

export default router;
