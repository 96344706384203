<template>
  <div
    v-loading.fullscreen.lock="store.state.loading.status"
    :element-loading-text="store.state.loading.text"
    element-loading-background="rgba(218, 218, 218, 0.8)"
  >
    <router-view />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();
</script>
