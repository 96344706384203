import axios from "axios";
const $http = axios.create({
  baseURL: process.env.VUE_APP_API + "/api",
});

// 攔截請求
$http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("oceanPowerToken");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 攔截回應
$http.interceptors.response.use(
  (res) => {
    switch (res.status) {
      case 200:
        return Promise.resolve(res);
      default:
        console.log(res.status);
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          console.error(error.response);
          break;
        case 401:
          localStorage.setItem("oceanPowerToken", "");
          console.error(error);
          location.href = "/login";
          break;
        case 403:
          localStorage.setItem("oceanPowerToken", "");
          console.error(error);
          location.href = "/login";
          break;
        default:
          console.error("攔截錯誤請求", error.response.status);
          return Promise.reject(error);
      }
    }
  }
);

export default $http;
