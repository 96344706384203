import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/main.scss";
import http from "./http.js";
import ElementPlus from "element-plus";
import zhTw from "element-plus/dist/locale/zh-tw.mjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const app = createApp(App);
app.config.globalProperties.$http = http;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store).use(router).use(ElementPlus, { locale: zhTw }).mount("#app");
