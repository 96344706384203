import { createStore } from "vuex";

export default createStore({
  state: {
    // 遮罩
    loading: { status: false, text: "" },
  },
  getters: {},
  mutations: {
    // 更改遮罩狀態
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {},
  modules: {},
});
